<template>
  <component :is="renderComponent" v-bind="$props" />
</template>

<script>
import { camelCase, startCase } from "lodash";

export default {
  name: "LinkBase",
  props: {
    action: {
      type: Object,
      required: true,
    },
  },
  computed: {
    renderComponent() {
      if (this.action?.variant && this.action?.variant !== "default") {
        return startCase(camelCase(this.action.variant)).replace(/ /g, "");
      }
      return "DefaultLink";
    },
  },
};
</script>

<style scoped></style>
