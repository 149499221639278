<template>
  <div class="core-form-radio">
    <div>
      <label for="enabled">{{ labelOn }}</label>
      <input type="radio" :value="true" id="enabled" v-model="radio" />
    </div>

    <div>
      <label for="disabled">{{ labelOff }}</label>
      <input type="radio" :value="false" id="disabled" v-model="radio" />
    </div>
  </div>
</template>

<script>
export default {
  name: "CoreFormRadio",
  props: {
    labelOn: {
      type: String,
      default: "On",
    },
    labelOff: {
      type: String,
      default: "Off",
    },
    initialValue: {
      type: [Boolean, String],
      default: true,
    },
  },
  data() {
    return {
      radio: this.initialValue,
    };
  },
};
</script>

<style lang="scss" scoped>
.core-form-radio {
  display: flex;
  gap: 24px;

  div {
    display: flex;
    align-items: center;

    label {
      color: #3b3b3b;
      font-size: 13px;
    }
  }

  input[type="radio"] {
    box-shadow: none;
  }
}
</style>
