<template>
  <div class="markdown-container">
    <component :is="component" v-html="content" />
  </div>
</template>

<script>
export default {
  name: "Markdown",
  props: {
    content: {
      type: String,
      default: "",
    },
    component: {
      type: String,
      default: "div",
    },
  },
};
</script>
