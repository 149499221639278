<template>
  <a :href="url">{{ text }} </a>
</template>

<script>
export default {
  name: "DefaultLink",
  computed: {
    text() {
      if (this.$attrs.action?.text) {
        return this.$attrs.action.text;
      }
      return "";
    },
    url() {
      if (this.$attrs.action?.url) {
        return this.$attrs.action.url;
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
a {
  color: theme-color(eggplant90);
  text-decoration: none;
  transition: all 0.3s;

  &:hover {
    text-decoration: underline;
  }
}
</style>
