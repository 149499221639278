<template>
  <div class="banner-base">
    <div class="banner">468x60</div>
  </div>
</template>

<script>
export default {
  name: "BannerBase",
};
</script>

<style lang="scss" scoped>
.banner-base {
  .banner {
    background: #f1f1f1;
    height: 60px;
    width: 468px;
    justify-content: center;
    display: flex;
    align-items: center;
    color: rgba(0, 0, 0, 0.5);
  }
}
</style>
