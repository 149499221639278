<template>
  <header>
    <h1>
      <strong>{{ vendorTitle }}</strong> {{ appTitle }}
    </h1>
  </header>
  <div class="main-wrapper">
    <router-view />
  </div>
</template>

<script>
export default {
  name: "App",
  mounted() {
    document.title = `${this.vendorTitle} ${this.appTitle}`;
  },
};
</script>

<style lang="scss">
@import "@/theme/theme2.scss";

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

header {
  background: #fff;
  border-bottom: 1px solid #f6f6f6;
  padding: 20px;

  h1 {
    font-weight: normal;
    margin: 0;
    padding: 0;
    font-size: 20px;
  }
}

.main-wrapper {
  display: flex;

  aside {
    min-width: 250px;
    padding: 24px;
    border-right: 1px solid #f6f6f6;
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    height: 50vh;

    ul.side-navigation {
      list-style: none;
      margin: 0;
      padding: 6px 0;
    }
  }
  main {
    padding: 0 40px;
  }
}

#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
}
</style>
