<template>
  <div class="core-form-select">
    <label for="select">Select Dropdown</label>
    <select v-model="select" id="select">
      <option>Please select</option>
      <option value="Test A">A</option>
      <option value="Test B">B</option>
      <option value="Test C">C</option>
    </select>
  </div>
</template>

<script>
export default {
  name: "CoreFormSelect",
  data() {
    return {
      select: "",
    };
  },
};
</script>

<style lang="scss" scoped>
.core-form-select {
  display: flex;
  gap: 20px;
  align-items: center;

  label {
    font-size: 12px;
  }

  select {
    min-width: 200px;
    font-size: 13px;
  }
}
</style>
