<template>
  <div class="call-to-action-base">
    <a class="call-to-action primary" @click="clickHandler"
      >Call To Action (Primary)</a
    >
    <a class="call-to-action secondary" @click="clickHandler"
      >Call To Action (Secondary)</a
    >
  </div>
</template>

<script>
export default {
  name: "CallToActionBase",
  props: {
    url: {
      type: String,
      default: "https://www.example.come",
    },
    title: {
      type: String,
      default: "Click me please",
    },
  },
  methods: {
    clickHandler() {
      this.$emit("buttonClick");
    },
  },
};
</script>

<style lang="scss" scoped>
.call-to-action-base {
  .call-to-action {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--color-primary);
    color: white;
    padding: 12px 24px;
    font-weight: 600;
    transition: ease-in-out all 0.2s;
    cursor: pointer;
    margin: 24px 0;

    &.secondary {
      background: var(--color-secondary);

      &:hover {
        color: var(--color-secondary);
      }
    }

    &:hover {
      background: #f6f6f6;
      color: var(--color-primary);
    }
  }
}
</style>
