<template>
  <div class="banner-variant-primary">Primary Banner Variant</div>
</template>

<script>
export default {
  name: "BannerVariantPrimary",
};
</script>

<style scoped></style>
