<template>
  <div class="badge-container">
    <div class="badge" :style="`color: ${color}; border-color: ${color}`">
      {{ title }}
    </div>
  </div>
</template>

<script>
export default {
  name: "Badge",
  props: {
    title: {
      type: String,
      default: "",
    },
    color: {
      type: String,
      default: "#79c153",
    },
  },
};
</script>

<style lang="scss" scoped>
.badge-container {
  .badge {
    font-weight: bold;
    border: 2px solid;
    border-radius: 2px;
    background-color: #fff;
    padding: 0 5px;
    display: inline-block;
  }
}
</style>
