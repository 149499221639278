<template>
  <div class="bread-crumbs">
    <ul>
      <li>Home</li>
      <li v-for="(item, index) in items" :key="`item-${index}`">
        {{ item.title }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: "BreadCrumbs",
  props: {
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.bread-crumbs {
  ul {
    list-style: none;
    display: flex;
    gap: 12px;
    padding: 0;
    margin: 0;

    li {
      font-size: 13px;

      &:not(:last-child):after {
        content: ">";
        margin: 0 0 0 10px;
        text-align: center;
        justify-content: center;
      }
      &:last-child {
        color: #aaa;
      }
    }
  }
}
</style>
