<template>
  <button class="secondary-button" @click="click">{{ linkTitle }}</button>
</template>

<script>
export default {
  name: "SecondaryButton",
  props: {
    title: {
      type: String,
      default: "Click me!",
    },
    click: {
      type: Function,
      default: () => console.log("user clicked button"),
    },
  },
  computed: {
    linkTitle() {
      if (this.$attrs.action?.text) {
        return this.$attrs.action.text;
      }
      return this.title;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./Buttons";

button.secondary-button {
  background: theme-color(white);
  color: theme-color(eggplant);
  border: 1px solid theme-color(eggplant);
  font-weight: 600;
  transition: ease-in-out all 0.2s;

  &:hover {
    background: theme-color(eggplant);
    color: theme-color(white);
  }
}
</style>
