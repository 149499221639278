<template>
  <div class="core-form-input">
    <input type="text" value="Text Input" placeholder="Enter Text" />
  </div>
</template>

<script>
export default {
  name: "CoreFormInput",
};
</script>

<style scoped></style>
