import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

// Import Global Components
import BreadCrumbs from "@/components/CoreComponents/BreadCrumbs/BreadCrumbs.vue";
import FullWidthPicture from "@/components/CoreComponents/FullWidthPicture/FullWidthPicture.vue";
import Heading from "@/components/CoreComponents/Heading/Heading.vue";
import LinkBase from "@/components/CoreComponents/Link/LinkBase.vue";
import DefaultLink from "@/components/CoreComponents/Link/variants/DefaultLink.vue";
import PrimaryButtonLink from "@/components/CoreComponents/Link/variants/PrimaryButtonLink.vue";
import SecondaryButtonLink from "@/components/CoreComponents/Link/variants/SecondaryButtonLink.vue";
import Markdown from "@/components/CoreComponents/Markdown/Markdown.vue";
import Panel from "@/components/CoreComponents/Panel/Panel.vue";
import PanelWithTable from "@/components/CoreComponents/PanelWithTable/PanelWithTable.vue";
import Table from "@/components/CoreComponents/Table/Table.vue";
import CoreButtonBase from "@/components/CoreFormElements/CoreButton/CoreButtonBase.vue";
import DefaultButton from "@/components/CoreFormElements/CoreButton/variants/DefaultButton.vue";
import IconButton from "@/components/CoreFormElements/CoreButton/variants/IconButton.vue";
import SecondaryButton from "@/components/CoreFormElements/CoreButton/variants/SecondaryButton.vue";
import CoreFormInput from "@/components/CoreFormElements/CoreFormInput/CoreFormInput.vue";
import CoreFormInputA from "@/components/CoreFormElements/CoreFormInput/variants/CoreFormInputA.vue";
import CoreFormInputB from "@/components/CoreFormElements/CoreFormInput/variants/CoreFormInputB.vue";
import CoreFormRadio from "@/components/CoreFormElements/CoreFormRadio/CoreFormRadio.vue";
import CoreFormSelect from "@/components/CoreFormElements/CoreFormSelect/CoreFormSelect.vue";
import Badge from "@/components/Ecommerce/Badge/Badge.vue";
import BannerBase from "@/components/Ecommerce/Banner/BannerBase.vue";
import BannerVariantPrimary from "@/components/Ecommerce/Banner/BannerVariantPrimary.vue";
import CallToActionBase from "@/components/Ecommerce/CallToAction/CallToActionBase.vue";
import BrillianceCallToAction from "@/components/Ecommerce/CallToAction/variants/BrillianceCallToAction.vue";
import ToggleBase from "@/components/Ecommerce/Toggle/ToggleBase.vue";


// Create App instance
const app = createApp(App).use(store).use(router)

// Register Global Components
app.component("BreadCrumbs", BreadCrumbs);
app.component("FullWidthPicture", FullWidthPicture);
app.component("Heading", Heading);
app.component("LinkBase", LinkBase);
app.component("DefaultLink", DefaultLink);
app.component("PrimaryButtonLink", PrimaryButtonLink);
app.component("SecondaryButtonLink", SecondaryButtonLink);
app.component("Markdown", Markdown);
app.component("Panel", Panel);
app.component("PanelWithTable", PanelWithTable);
app.component("Table", Table);
app.component("CoreButtonBase", CoreButtonBase);
app.component("DefaultButton", DefaultButton);
app.component("IconButton", IconButton);
app.component("SecondaryButton", SecondaryButton);
app.component("CoreFormInput", CoreFormInput);
app.component("CoreFormInputA", CoreFormInputA);
app.component("CoreFormInputB", CoreFormInputB);
app.component("CoreFormRadio", CoreFormRadio);
app.component("CoreFormSelect", CoreFormSelect);
app.component("Badge", Badge);
app.component("BannerBase", BannerBase);
app.component("BannerVariantPrimary", BannerVariantPrimary);
app.component("CallToActionBase", CallToActionBase);
app.component("BrillianceCallToAction", BrillianceCallToAction);
app.component("ToggleBase", ToggleBase);


// Define Global Variable (Component List)
const componentList = [
  { groupName: "CoreComponents", name: "BreadCrumbs", description: "This component is being used in the page navigation.", figmaUrl: "https://www.figma.com/file/4j7z0nDjBdal9eOnilGuke/PDP-Space?node-id=1022%3A9271", props: {"items":[{"title":"Wedding & Engagement","url":""},{"title":"Wedding Bands","url":""},{"title":"Women's Wedding Bands","url":""},{"title":"Mother's Day Specials","url":""}]}, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreComponents", name: "FullWidthPicture", description: "Full-Width Image component", figmaUrl: "https://www.figma.com/file/4j7z0nDjBdal9eOnilGuke/PDP-Space?node-id=853%3A7773", props: null, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreComponents", name: "Heading", description: "This is the default Header component", figmaUrl: null, props: {"text":"Mother's Day Specials","tag":"h1"}, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreComponents", name: "LinkBase", description: "This is the base component for our link variants.", figmaUrl: null, props: {"action":{"text":"Click me!","url":"https://www.example.org","newTab":"false"}}, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreComponents", name: "DefaultLink", description: "Default Link component", figmaUrl: null, props: {"action":{"text":"Click me!","url":"https://www.example.org","newTab":"false"}}, parent: "Link", isVariant: true },
  { groupName: "CoreComponents", name: "PrimaryButtonLink", description: null, figmaUrl: null, props: null, parent: "Link", isVariant: true },
  { groupName: "CoreComponents", name: "SecondaryButtonLink", description: null, figmaUrl: null, props: null, parent: "Link", isVariant: true },
  { groupName: "CoreComponents", name: "Markdown", description: null, figmaUrl: null, props: null, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreComponents", name: "Panel", description: "This our default panel component.", figmaUrl: null, props: {"title":"Panel Headline","body":"Here goes the body"}, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreComponents", name: "PanelWithTable", description: "This our default table component.", figmaUrl: null, props: {"panelTitle":"Panel with Table included","table":{"headers":["Header 1","Header 2","Header 3"],"rows":[{"columns":["Row A, Column 1","Row A, Column 2","Row A, Column 3"]},{"columns":["Row B, Column 1","Row B, Column 2","Row B, Column 3"]},{"columns":["Row C, Column 1","Row C, Column 2","Row C, Column 3"]}]}}, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreComponents", name: "Table", description: "This our default table component.", figmaUrl: null, props: {"headers":["Header 1","Header 2","Header 3"],"rows":[{"columns":["Row 1, Column 1","Row 1, Column 2","Row 1, Column 3"]},{"columns":["Row 1, Column 1","Row 1, Column 2","Row 1, Column 3"]}]}, parent: "CoreComponents", isVariant: false },
  { groupName: "CoreFormElements", name: "CoreButtonBase", description: null, figmaUrl: null, props: null, parent: "CoreFormElements", isVariant: false },
  { groupName: "CoreFormElements", name: "DefaultButton", description: null, figmaUrl: null, props: null, parent: "CoreButton", isVariant: true },
  { groupName: "CoreFormElements", name: "IconButton", description: "This button component accepts an icon as well as an icon color prop.", figmaUrl: null, props: null, parent: "CoreButton", isVariant: true },
  { groupName: "CoreFormElements", name: "SecondaryButton", description: null, figmaUrl: null, props: null, parent: "CoreButton", isVariant: true },
  { groupName: "CoreFormElements", name: "CoreFormInput", description: null, figmaUrl: null, props: null, parent: "CoreFormElements", isVariant: false },
  { groupName: "CoreFormElements", name: "CoreFormInputA", description: null, figmaUrl: null, props: null, parent: "CoreFormInput", isVariant: true },
  { groupName: "CoreFormElements", name: "CoreFormInputB", description: null, figmaUrl: null, props: null, parent: "CoreFormInput", isVariant: true },
  { groupName: "CoreFormElements", name: "CoreFormRadio", description: null, figmaUrl: null, props: null, parent: "CoreFormElements", isVariant: false },
  { groupName: "CoreFormElements", name: "CoreFormSelect", description: null, figmaUrl: null, props: null, parent: "CoreFormElements", isVariant: false },
  { groupName: "Ecommerce", name: "Badge", description: "This our badge component.", figmaUrl: null, props: {"title":"In-Progress","color":"orange"}, parent: "Ecommerce", isVariant: false },
  { groupName: "Ecommerce", name: "BannerBase", description: null, figmaUrl: null, props: null, parent: "Ecommerce", isVariant: false },
  { groupName: "Ecommerce", name: "BannerVariantPrimary", description: null, figmaUrl: null, props: null, parent: "Ecommerce", isVariant: false },
  { groupName: "Ecommerce", name: "CallToActionBase", description: null, figmaUrl: null, props: null, parent: "Ecommerce", isVariant: false },
  { groupName: "Ecommerce", name: "BrillianceCallToAction", description: "This component shows the Michael Hill Brilliance Call Out", figmaUrl: "https://www.figma.com/file/4j7z0nDjBdal9eOnilGuke/PDP-Space?node-id=1787%3A21607", props: {"title":"Join Michael Hill Brilliance","text":"Receive access to member prices, exclusive offers, birthday and anniversary gifts, plus enjoy $20 off your first purchase of $100 or more.","picture":"https://via.placeholder.com/150","action":{"text":"Join free today ›","url":"https://www.example.org","variant":"secondary-button"}}, parent: "CallToAction", isVariant: true },
  { groupName: "Ecommerce", name: "ToggleBase", description: null, figmaUrl: null, props: null, parent: "Ecommerce", isVariant: false },
];

app.config.globalProperties.globalComponentList = componentList;
app.config.globalProperties.appTitle = `${process.env.VUE_APP_TITLE}`;
app.config.globalProperties.vendorTitle = `${process.env.VUE_APP_VENDOR_TITLE}`;
app.mount("#app");