<template>
  <div class="toggle-base">Toggle Base component</div>
</template>

<script>
export default {
  name: "ToggleBase",
};
</script>

<style scoped></style>
