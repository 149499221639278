<template>
  <div class="core-form-input-a">
    <input type="text" value="" placeholder="Please enter a text" />
  </div>
</template>

<script>
export default {
  name: "CoreFormInputA",
};
</script>

<style lang="scss" scoped></style>
