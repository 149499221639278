<template>
  <div class="brilliance-cta-container">
    <div class="flex-header">
      <div class="image-container">
        <div v-if="picture" class="slim-banner-cta__banner__picture-container">
          <FullWidthPicture
            class-name="slim-banner-cta__banner__picture-container__element"
            :picture="picture"
          />
        </div>
      </div>
      <div v-if="title || tagline" class="title-container">
        <small
          v-if="tagline"
          class="callToAction__tagline"
          :color="taglineColor"
          >{{ tagline }}</small
        >
        <Heading
          v-if="title"
          class="callToAction__title"
          :text="title"
          v-bind="titleOptions"
        />
      </div>
      <div class="content-container">
        <Markdown
          v-if="text"
          class="callToAction__text"
          :content="text"
          v-bind="textOptions"
        />
      </div>
    </div>

    <div class="callToAction__actions">
      <div class="link-container">
        <div v-if="action" class="first-link">
          <LinkBase
            class="callToAction__actions__primary-action"
            :action="action"
            :ga-options="{
              type: 'DefaultCallToAction',
              group: `DefaultCallToAction:${$parent.$parent.id}`,
            }"
            :log-click="true"
          />
        </div>

        <template v-if="action && secondaryAction">
          <div class="divider">|</div>
        </template>

        <div v-if="secondaryAction" class="first-link">
          <LinkBase
            class="callToAction__actions__secondary-action"
            v-bind="secondaryAction"
            :ga-options="{
              type: 'DefaultCallToAction',
              group: `DefaultCallToAction:${$parent.$parent.id}`,
            }"
            :log-click="true"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "BrillianceCallToAction",
  props: {
    id: {
      type: String,
    },
    tagline: {
      type: String,
    },
    taglineColor: {
      type: String,
    },
    title: {
      type: String,
    },
    titleOptions: {
      type: Array,
    },
    text: {
      type: String,
    },
    textOptions: {
      type: String,
    },
    action: {
      type: String,
    },
    actionOptions: {
      type: String,
    },
    secondaryAction: {
      type: String,
    },
    secondaryActionOptions: {
      type: String,
    },
    picture: {
      type: String,
    },
    icon: {
      type: String,
    },
    variant: {
      type: String,
    },
  },
};
</script>

<style lang="scss" scoped>
.flex-header {
  display: flex;
  justify-content: space-between;
  gap: theme-spacing(6);
  align-items: center;
  @include breakpoint(mobile, desktop) {
    gap: theme-spacing(5);
  }
  .image-container {
    max-width: 147px;
    min-width: 100px;
    width: 100%;
  }
}
.link-container {
  margin-top: theme-spacing(5);
  display: flex;
  gap: theme-spacing(4);

  .divider {
    color: theme-colour(eggplant);
  }
}
</style>
